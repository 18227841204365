import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'
import React from 'react'
import PropTypes from 'prop-types'

// // 1. Your WalletConnect Cloud project ID
const projectId = process.env.REACT_APP_PUBLIC_WALLETCONNECT_PROJECT_ID || ''

// 2. Set chains
const bscTestnet = {
  chainId: 97,
  name: 'Binance Smart Chain Testnet',
  currency: 'BNB',
  explorerUrl: 'https://testnet.bscscan.com',
  rpcUrl: process.env.REACT_APP_RPC_URL
}
const bsc = {
  chainId: 56,
  name: 'Binance Smart Chain',
  currency: 'BNB',
  explorerUrl: 'https://bscscan.com',
  rpcUrl: process.env.REACT_APP_RPC_URL
}

// 3. Create a metadata object
const metadata = {
  name: 'NORA',
  description: 'NORA642',
  url: 'https://www.nora642.com', // origin must match your domain & subdomain
  icons: ['https://www.nora642.com/static/media/logo.47fab18af8e51995012e121f0ae325e2.svg']
}

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  metadata,
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: false, // true by default
  enableTrustWallet: true
})

// 5. Create a Web3Modal instance
createWeb3Modal({
  ethersConfig,
  chains: [process.env.REACT_APP_NODE_ENV === 'dev' ? bscTestnet : bsc],
  projectId,
  enableAnalytics: true // Optional - defaults to your Cloud configuration
})

export function WalletConnect ({ children }) {
  return (
    <div>
      {children}
    </div>
  )
}

WalletConnect.propTypes = {
  children: PropTypes.node.isRequired
}
